@import "../../mixins.scss";

.her {

  .page-outer {
    padding-top: calc(87px + 3rem);
    padding-bottom: 4rem;

    @media (max-width: 425px) {
      padding-top: calc(87px + 1rem);
    }


    .page-inner {
      max-width: 700px;
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      flex-direction: column;

      .title-page {
        position: relative;
        display: flex;
        align-self: flex-start;
        margin-bottom: 1rem;

        &:after {
          @include wait(#e7e6e9)
        }
      }

      h1 {
        font-weight: 400;
        font-size: 5rem;
        text-transform: uppercase;
      }

    }

  }


}
