@import "../../mixins.scss";

.her {

  .her-outer-main {
    &.active {
      h1.h1-her {
        transform: translate(0,0);
        a {
          color: black !important;
        }

        //@media(max-width: 425px){
        //  transform: translate(0, 0);
        //}

      }
    }
  }

  .her-outer-main {

    h1.h1-her {
      padding-left: 1.7rem;
      margin-bottom: 0;
      font-size: 1.1rem;
      font-weight: 400;
      text-transform: uppercase;
      transform: translate(2rem, 2rem);
      transition: all 0.3s ease;
      position: fixed;
      // top: 2rem;
      top: 32px;
      z-index: 100000000;

      @media(max-width: 425px) {
        top: 24px;
        transform: translate(0, -2px) !important;
        font-size: 1.5rem;
      }

      a {
        color: white;
        position: relative;

        &:after {
          //@include wait(#b8b8b8);
          @include wait(#d4d4d4);
        }

      }
    }

    &.menuShow {
      h1.h1-her {
        @media(max-width: 425px) {
          //top: 0;
          //padding-left: 0;
          //transform: translate(2rem, 2rem);
          //transform: translate(0, 0);
          //transform: translate(0, -2px);
          color: black;
          //font-size: 1.5rem;

          a {
            color: black;
          }
        }
      }
    }
  }

  .cible-view {
    //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99);
    will-change: transform;
    position: relative;
    overflow: hidden;

    .call-to-action {
      margin-top: 0.7rem;
    }

    .status {
      cursor: pointer;
    }

    img, .fuck {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      will-change: opacity;
      opacity: 1;

      &.loaded {
        opacity: 1;
        transition: opacity 0.6s ease 0.200s;
      }

    }

    h2 {
      color: white;
    }

    .chapitre {
      color: white;

      .number {
        color: #fff;
        background: #f95e7d;
        padding: 0.2rem;
        padding-top: 7px;
        font-weight: 700;
        font-size: 14px;
        border-radius: 5px;
        margin-right: 15px;
        width: 23px;
        height: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    .inner-head {
      height: 100%;
      width: 100%;
      position: relative;

      &.not-ready{
        &:after {
          @include wait(#ececec)
        }
      }

      &:after {
        @include wait(#ececec)
      }

      @keyframes animTest {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      img {
        opacity: 0;

        &.active {
          opacity: 1;
        }
        &.loaded {
          opacity: 1;
          transition: opacity 0.6s ease 0.200s;
        }


      }

      &.ready {
        //
        //.fx-img {
        //  opacity: 1;
        //}
        //
        //img {
        //  opacity: 1;
        //}

      }

    }

    .fx-img {
      //display: none !important;
      top: -74%;
      left: -100%;
      width: 200%;
      height: 200%;
      content: "";
      position: absolute;
      //opacity: 1;
      background: rgba(0, 0, 0, 0.1);
      //background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 27%);
      z-index: 2;
      //transition: all 0.2s ease-out;
    }

    .imageView {
      width: 101%;
      height: 101%;
      will-change: transform;
      object-fit: cover;
      top: 0;
      left: 0;
      position: absolute;

      img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        will-change: opacity;
        opacity: 1;
      }
      //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99) !important;
    }

    .start-btn {
      will-change: transform;
      //transition: all 0.50s ease-in-out 0.20s !important;
    }

    &.article-view {
      height: 78vh !important;
      transform: matrix(0.99999, 0, 0, 0.99999, 0, -200);

      iframe {
        width: 100%;
        height: calc(100% - 200px);
        transform: translateY(200px);
      }

      // before 375px
      @media (max-width: 425px) {
        height: 72vh !important;
        transform: translateY(-70px);

        iframe {
          height: calc(100% - 70px);
          transform: translateY(70px);
        }

      }

      @media (max-width: 320px) {
        transform: translateY(-40px);

        iframe {
          height: calc(100% - 40px);
          transform: translateY(40px);
        }

      }

      .imageView {
        transform: translateY(100px);

        @media (max-width: 425px) {
          transform: translateY(35px);

        }

        @media (max-width: 320px) {
          transform: translateY(20px);
        }

      }

      .start-btn {
        opacity: 0;
      }

      .outer-i {
        display: flex;
        &:after {
          //@include wait(#b8b8b8);
          @include wait(#d4d4d4);
        }
      }

      h2 {
        width: 100% !important;
      }

      img, .fx-img{
        //transform: translateY(200px);
        //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99) !important;
      }

      .fx-img {
        //transform: translateY(200px);
      }

      .start-btn {
        //transform: translateY(101%);
        //transform-origin: bottom;
      }
    }
    &.couverture-view {
      height: 100vh;

      .fx-img {
        display: none;
      }

      .card-couv {
        background: #E2002B;
        padding: 3.5rem;
        padding-right: 4rem;
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0;
        //width: 65%;
        width: 61%;
        max-width: 800px;
        z-index: 1;

        @media (max-width: 1025px) and (min-height: 900px) {
          width: 73%;
          padding-right: 3rem;
        }

        @media (max-width: 425px) {
          width: 94%;
          padding: 2.5rem;
          padding-right: 3rem;
        }

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          h1 {
            text-align: right;
            color: white;
            text-transform: uppercase;
            font-size: 8rem !important;
            letter-spacing: -2px;
            line-height: 7rem !important;
            font-weight: 400 !important;

            @media (max-width: 425px) {
              font-size: 7rem !important;
              line-height: 6rem !important;
            }

          }

          .logo {
            width: 158px;
            height: 41px;
            margin-bottom: 2rem;

            .logo-inner {
              width: 100%;
              height: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              position: relative !important;
            }
          }

        }

      }

      @media (max-width: 375px) {
        height: 100vh;
      }

      .time, .status {
        &:after {
          @include wait(#ececec)
        }
      }

      .start-btn {
        //transform: translateY(0%);
        //transform-origin: bottom;
      }

    }
  }
}
