//     padding-right: 1rem;

@media (min-height: 900px) and (max-width: 1025px){

  .her {
    .main {
      .header {
        padding-right: 1rem;
        width: calc(100% - 72px);

        .scroll-block {
          & > div:first-child {
            flex-basis: 0;
            display: none;
          }
          .scroll-inner {
            flex-basis: calc(100%);
            max-width: 100%;
          }
        }

      }
    }
  }

}

@media (max-width: 425px) {

  .her {
    .main {
      .header {
        width: calc(100%);
        height: 60px;
        z-index: 1000000;
        padding-right: 4.7rem;

        &.menuShow {
          .inner h1 a {
            color: white;
          }
        }

        .searchBtn.isSearching {
          .icon-close { transform: scale(1.7) !important; }
        }

        .inner {
          .searchBtn {
            //transform: scale(1.4) translateY(2px);
            transform: scale(1.4) translateY(1.8px);
          }
        }

        &.menuShow {
          //background: #2e3dcc;
          background: white;
          //padding-right: 4.7rem;

          .searchBtn {
            display: none;
            i {
              &:before {
                color: black;
              }
            }
          }

          .scroll-block {
            visibility: hidden;
          }

        }

        .scroll-block {
          position: absolute;
          top: 0;
          transform: translateY(-1px);
        }

        .menu-numero {
          display: none;
        }

      }
    }
  }

}
