//@media (max-width: 768px){
@media (min-height: 900px) and (max-width: 1025px){

  .her {
    .menu {
      left: 72px;
      width: calc(100vw - 72px);

      .footer-menu ul li {
        margin-right: 1rem !important;
      }

    }

    .menu-inner {

      .articles-test {

        & > ul {
          & > li {
            //flex: 0 1 calc(52% - 58px / 2);
            padding: 5px;
            //padding: 15px;
            padding-right: 0;
          }
        }

      }

    }
  }

}

@media (max-width: 425px){

  .her {
    .menu {
      width: calc(100vw);
      left: 0;
      overflow: auto;
      height: 100vh !important;
      //background: #2e3dcc;
      background: white;
      z-index: 130000;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #E1E1E1;
      }

      &::-webkit-scrollbar-thumb {
        background: #000000;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #000000;
      }

      .footer-menu {
        position: relative;
        padding-left: 0;
        padding-top: 2rem;
        padding-right: 0;
        //padding-bottom: 2rem;
        height: auto !important;
        padding-bottom: 5rem;

        .inner-footer-menu {
          max-width: 100%;
          transform: translateX(0);
          flex-direction: column;

          .credits {
            margin-top: 1.5rem;
          }

        }

        ul {
          flex-direction: column;
          text-align: center;

          li {
            margin-right: 0 !important;
            //font-size: 1.2rem;
            font-size: 1.7rem;
            margin-bottom: 0.7rem;
          }

        }

      }

      .menu-inner {
        //padding-top: 72px;
        padding-top: 0px;
        padding-left: 6px;
        flex-direction: column;
        position: relative;
        height: auto;

        .categories {
          //width: 70%;
          width: 77%;
          height: auto;
          min-width: initial;

          .chapitre-title {
            font-size: 6rem;
            line-height: 6rem;

            i.ico-her-question_mark {
              font-size: 4rem;
              line-height: 5rem;
              margin-left: 0.7rem;
              transform: translateY(3px);
            }

          }
        }

        .menu-numero {
          padding-left: 15px;
          padding-right: 15px;
          position: relative;

          i.icon-chevron_down {
            color: white;
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% - 2px));
            right: 1.5rem;
            font-size: 1.7rem;
          }

          .numeros-menu-outer {
            position: relative;
            background: rgba(66, 62, 222, 0.6901960784313725);
            padding: 12px;
            z-index: 1;
            width: 99%;
            margin-top: 1rem;
            bottom: 0;
            left: 0;
            /* -webkit-transform: translateY(100%); */
            -ms-transform: translateY(100%);
            transform: translateY(0%);
            padding-top: 0;
            padding-bottom: 0 !important;

            select {
              color: white;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              width: 100%;
              background: transparent;
              font-size: 1.2rem;
              padding-left: 0.7rem;
              border: 0 !important;
              outline: none;
            }

            .numeros-menu li {
              margin-bottom: 2.2em;
              margin-top: 1rem;

              a {
                font-size: 1.05rem;
                color: white;
              }

            }

          }

          .menu-numeros-btn {
            font-size: 0.9rem;
          }

        }

        .articles-test {
          //margin-top: 1.4rem;
          margin-top: 3.4rem;
          padding-bottom: 0;
          overflow-y: inherit;
          overflow-x: inherit;
          -webkit-overflow-scrolling: auto;
          //overflow: inherit;

          .title {
            margin-bottom: 1rem;

            .sommaire {
              font-size: 4.8rem;
              line-height: 4.8rem;
            }
            .sommaire-title {
              display: none;
            }
          }

          &::-webkit-scrollbar {
            width: 0;
            display: none;
          }

          .chapitre-article {
            .titles {
              padding-left: 15px;
              padding-bottom: 15px;
              padding-top: 15px;
              padding-right: 15px;

              .category {
                font-size: 2.2rem;
                color: #E2002B;
                text-transform: uppercase;
                letter-spacing: 1px;
                line-height: 2.3rem;
                margin-bottom: 0.4rem;
              }

            }
          }

          .chapitre-article a {
            flex-direction: column;

            .wallpaper {
              width: 100%;
            }

          }

          .edito, .chiffre-cle {
            padding-left: 0;
            margin-left: 0;
          }

          > ul {
            padding-bottom: 3rem;
            padding-left: 15px;
            padding-right: 15px;

            > li {
              padding-left: 0;
              padding-right: 0;

              div.inner {
                flex-direction: column;

                .scrollbar-container {
                  margin-left: 0;
                  margin-right: 0;
                }
              }
            }

          }

        }

        .articles-test::-webkit-scrollbar {
          width: 5px;
        }

        .articles {
          height: auto;
          padding-bottom: 1rem;
          padding-top: 0;
          padding: 0;
          margin-top: 1rem;

          li {
            width: 100%;
            padding-right: 0;
            margin-right: 15px;
            flex: 0 0 auto;

            span.who {
              font-size: 2rem;
              line-height: 2.2rem;
              margin-top: 0.9rem;
              width: 100%;
              margin-bottom: 0.4rem;
            }

            .wallpaper {
              width: 100%;
            }
          }

          a {
            font-size: 1.2rem;
          }

        }

        .articles-test > ul {
          & > li {
            flex: 0 1 100%;
          }
        }

      }

    }
  }


}
